import type React from 'react';
import { FormattedMessage } from 'react-intl';

import { BodyCopy, Headline, Illustration, TextButton } from '..';
import { Layout } from '../layout';
import { Styled } from './activate-js-page.styled';
import { GearImage } from './gear-image';

export const ActivateJsPage: React.FC = () => {
  return (
    <noscript>
      <Layout showFooter forceShowHeader hideGdprImage showSimpleHeader={false}>
        <style>{`#javascript-content { display: none; }`}</style>

        <Illustration>
          <GearImage />
        </Illustration>

        <Styled.Container>
          <Headline size="xxlarge" sizeConfined="xxlarge">
            <FormattedMessage id="LOGIN_NOSCRIPT_TITLE" />
          </Headline>

          <BodyCopy size="large" sizeConfined="large">
            <FormattedMessage id="LOGIN_NOSCRIPT_PARAGRAPH" />
          </BodyCopy>

          <FormattedMessage id="LOGIN_NOSCRIPT_LINK_TARGET">
            {(linkTarget) => (
              // @ts-expect-error FIXME: SC6
              <TextButton size="medium" href={linkTarget}>
                <FormattedMessage id="LOGIN_NOSCRIPT_LINK_TEXT" />
              </TextButton>
            )}
          </FormattedMessage>
        </Styled.Container>
      </Layout>
    </noscript>
  );
};
