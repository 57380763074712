import type { PropsWithChildren } from 'react';

import { ActivateJsPage } from './activate-js-page';
import { BackgroundLayout as InternalBackgroundLayout } from './background-layout';
import { HtmlHead } from './html-head/html-head';
import type { LayoutProps as BasicLayoutProps } from './layout';
import { Layout as BasicLayout } from './layout';

export { Button, TextButton } from './button';
export { Illustration } from './illustration';
export { Headline, BodyCopy, Meta } from './typography';
export { MessageWithLink } from './message-with-link';
export { FormMessage } from './form-message';
export { PasswordInput } from './password-input';
export { PasswordStrengthMeter } from './password-strength-meter';
export { ContinueWithGoogle, ContinueWithApple } from './third-parties';
export { ResendCountdown } from './resend-countdown';
export { Divider } from './divider';
export { FormHeadline } from './form-headline';
export { GenericSkeleton } from './generic-skeleton';
export { StepCounter } from './step-counter';
export { Overlay } from './overlay';

export const Layout: React.FC<BasicLayoutProps> = (props) => {
  return (
    <>
      <HtmlHead />
      <ActivateJsPage />

      <div id="javascript-content">
        <BasicLayout {...props} />
      </div>
    </>
  );
};

export const BackgroundLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <HtmlHead />
      <ActivateJsPage />

      <div id="javascript-content">
        <InternalBackgroundLayout>{children}</InternalBackgroundLayout>
      </div>
    </>
  );
};
