import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import { messages } from './messages';

export const HtmlHead: React.FC = () => {
  const intl = useIntl();

  return (
    <Helmet
      title={intl.formatMessage(messages.metaTitle)}
      meta={[
        {
          name: 'description',
          content: intl.formatMessage(messages.metaDescription),
        },
      ]}
      link={[
        {
          rel: 'shortcut icon',
          href: 'https://www.xing.com/assets/frontend_minified/img/shared/xing_r1.ico',
          type: 'image/ico',
        },
        {
          rel: 'apple-touch-icon',
          href: 'https://www.xing.com/assets/frontend_minified/img/shared/xing_icon_apple.png',
        },
      ]}
    />
  );
};
