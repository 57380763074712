import * as React from 'react';

export const useMatchMedia = (media: string): boolean => {
  const initialMatch = global.window?.matchMedia?.(media).matches ?? false;

  const [matches, setMatches] = React.useState(initialMatch);

  React.useEffect(() => {
    const mql = window.matchMedia(media);
    setMatches(mql.matches);

    const eventListener = (event: MediaQueryListEvent): void => {
      setMatches(event.matches);
    };

    if (mql.addEventListener) {
      mql.addEventListener('change', eventListener);
    } else if (mql.addListener) {
      // addListener deprecated but still needed by Safari < 14
      mql.addListener(eventListener);
    }
    return () => {
      if (mql.removeEventListener) {
        mql.removeEventListener('change', eventListener);
      } else if (mql.removeListener) {
        // removeListener deprecated but still needed by Safari < 14
        mql.removeListener(eventListener);
      }
    };
  }, [media]);

  return matches;
};
