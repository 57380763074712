import { useLocation } from '@reach/router';
import Cookie from 'js-cookie';
import { useState } from 'react';

import { getMainHost } from '@xing-com/crate-login-utils';
import { useFetch, useHost, useLoginAppUrl } from '@xing-com/crate-xinglet';

const JSON_HEADERS = {
  'Content-Type': 'application/json; charset=utf-8',
  Accept: 'application/json',
};

const FORWARDED_COOKIES = [
  'xing_csrf_checksum',
  'xing_csrf_token',
  'login_session',
  '_session_id',
];

type ResultContentType = {
  authPartner?: Record<string, string>;
  knownUser?: Record<string, string>;
};

export function useLoginPage(): {
  execute: () => Promise<void>;
  loading: null | boolean;
  error: boolean;
  result: ResultContentType;
} {
  const host = useLoginAppUrl().replace(/\/$/, '');
  const homepage = getMainHost(host);
  const fetch = useFetch();
  const { getHostname } = useHost();
  const { search } = useLocation();
  const hostname = getHostname();
  const [loading, setLoading] = useState<null | boolean>(null);
  const [error, setError] = useState(false);
  const [result, setResult] = useState<ResultContentType>({});

  const isLocalhost = hostname.includes('localhost');

  const forwardedCookies = isLocalhost
    ? ['fake_login_state', 'auth_partner']
    : FORWARDED_COOKIES;

  const cookieString = forwardedCookies
    .map((key) =>
      Cookie.get(key)
        ? `${encodeURIComponent(key)}=${encodeURIComponent(String(Cookie.get(key)))}`
        : null
    )
    .filter((serializedCookie) => Boolean(serializedCookie))
    .join(';');

  const execute = async (): Promise<void> => {
    setLoading(true);

    const data = await fetch(`${host}/login/api/login${search}`, {
      headers: { ...JSON_HEADERS, Cookie: cookieString },
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
    });

    const parsedData = await data.json();

    if (parsedData.error === 'FULLY_LOGGED_IN') {
      window.location.href = parsedData.target ? parsedData.target : homepage;
      return;
    }

    if (data.status >= 400) {
      setLoading(false);
      setError(true);

      if (parsedData.target) {
        window.location.href = parsedData.target;
      }

      return;
    }

    setResult({
      authPartner: parsedData.auth_partner,
      knownUser: parsedData.known_user,
    });

    setLoading(false);
  };

  return { execute, loading, error, result };
}
