import styled from 'styled-components';

import { Button } from '@xing-com/button';
import { spaceXL } from '@xing-com/tokens';

export const GoogleButton = styled(Button)`
  width: 100%;
`;

export const AppleButton = styled(Button)`
  width: 100%;
  margin-top: ${spaceXL};
`;
