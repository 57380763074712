import styled from 'styled-components';

import {
  SkeletonHeadline,
  SkeletonBody,
  SkeletonButton,
  SkeletonImage,
  SkeletonInputBar,
} from '@xing-com/skeleton';
import { spaceXL } from '@xing-com/tokens';

const Image = styled(SkeletonImage)`
  width: 236px;
  height: 236px;
`;

const Headline = styled(SkeletonHeadline)`
  margin-bottom: ${spaceXL};
`;

const BodyCopy = styled(SkeletonBody)`
  margin-bottom: ${spaceXL};
`;

const Button = styled(SkeletonButton)`
  margin-bottom: ${spaceXL};
`;

const InputBar = styled(SkeletonInputBar)`
  margin-bottom: ${spaceXL};
`;

export const Styled = {
  Image,
  Headline,
  BodyCopy,
  Button,
  InputBar,
};
