import type { NavigateOptions } from '@reach/router';
import { useLocation, useNavigate } from '@reach/router';
import { useCallback } from 'react';

export type GetSearchParameter = (name: string) => string | null;
export type SetSearchParameter = (
  name: string,
  value: string,
  options?: NavigateOptions<any>
) => void;
export type DeleteSearchParameter = (
  name: string,
  options?: NavigateOptions<any>
) => void;

export type UseSearchParameter = {
  getSearchParameter: GetSearchParameter;
  setSearchParameter: SetSearchParameter;
  deleteSearchParameter: DeleteSearchParameter;
};

export type UseSearchParameterFn = () => {
  getSearchParameter: GetSearchParameter;
  setSearchParameter: SetSearchParameter;
  deleteSearchParameter: DeleteSearchParameter;
};

/**
 *
 * @returns UseSearchParameter
 */
export const useSearchParameter: UseSearchParameterFn = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getSearchParameter: GetSearchParameter = useCallback(
    (name) => {
      const search = new URLSearchParams(location.search);
      return search.get(name);
    },
    [location.search]
  );

  const setSearchParameter: SetSearchParameter = useCallback(
    (name, value, options = { replace: true, state: location.state }) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(name, value);
      navigate(
        `${location.pathname}?${searchParams
          .toString()
          .replaceAll('+', '%20')}`,
        options
      );
    },
    [location.pathname, location.search, location.state, navigate]
  );

  const deleteSearchParameter: DeleteSearchParameter = useCallback(
    (name, options = { replace: true, state: location.state }) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete(name);
      const searchParamsString =
        searchParams.toString().length > 0
          ? `?${searchParams.toString().replaceAll('+', '%20')}`
          : '';

      navigate(`${location.pathname}${searchParamsString}`, options);
    },
    [location.pathname, location.search, location.state, navigate]
  );

  return { setSearchParameter, deleteSearchParameter, getSearchParameter };
};
