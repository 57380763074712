import styled from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import { space3XL, space4XL } from '@xing-com/tokens';

const ImageContainer = styled.div`
  align-items: center;
  justify-content: center;
  margin: ${space3XL} 0;
  text-align: center;
  display: flex;

  @media ${mediaConfined} {
    margin: ${space4XL} 0;
  }
`;

export const Styled = {
  ImageContainer,
};
