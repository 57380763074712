import styled, { css } from 'styled-components';

import { Button, TextButton } from '@xing-com/button';
import { spaceS, spaceXL } from '@xing-com/tokens';

const StretchButtonGroup = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const stretchButtonStyles = css`
  flex-grow: 1;
  margin-right: 0;
  margin-top: ${spaceS};
  text-align: center;
  margin-bottom: ${spaceXL};

  &:first-child {
    margin-top: 0;
  }
`;

const StretchButton = styled(Button)`
  ${stretchButtonStyles}
`;

const StretchTextButton = styled(TextButton)`
  ${stretchButtonStyles}
`;

export const Styled = {
  StretchButtonGroup,
  StretchButton,
  StretchTextButton,
};
