import type React from 'react';
import type { PropsWithChildren } from 'react';

import { getMainHost } from '@xing-com/crate-login-utils';
import { useHost } from '@xing-com/crate-xinglet';
import { Link } from '@xing-com/link';

import { Styled } from './background-layout.styles';
import energizer from './images/energizer.jpeg';

export const BackgroundLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { getHostname } = useHost();
  const mainHost = getMainHost(getHostname());
  return (
    <>
      <Styled.TopBar hideLoginButton hideSignupButton />

      <Styled.LoginContainer>
        <Styled.ColumnWithBackground>
          <Link href={mainHost}>
            <Styled.XingLogo variant="mono" size={96} />
          </Link>
          <Styled.Energizer src={energizer} loading="lazy" />
        </Styled.ColumnWithBackground>

        <Styled.Column>{children}</Styled.Column>
      </Styled.LoginContainer>
    </>
  );
};
