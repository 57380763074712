import type React from 'react';
import type { PropsWithChildren } from 'react';

import { useMatchMedia } from '@xing-com/crate-hooks-use-match-media';
import { LoginGdprImage as GdprImage } from '@xing-com/crate-login-gdpr-image';
import { getMainHost } from '@xing-com/crate-login-utils';
import { useHost } from '@xing-com/crate-xinglet';
import { mediaConfined } from '@xing-com/layout-tokens';
import { Footer } from '@xing-com/platform-layout-footer';
import { HeaderLoggedOut } from '@xing-com/platform-layout-header-logged-out';

import { Styled } from './layout.styles';

export type LayoutProps = PropsWithChildren<{
  hideHeader?: boolean;
  showFooter?: boolean;
  showSimpleHeader?: boolean;
  showSignUpButton?: boolean;
  forceShowHeader?: boolean;
  hideGdprImage?: boolean;
}>;

export const Layout: React.FC<LayoutProps> = ({
  children,
  hideHeader = false,
  forceShowHeader = false,
  showSimpleHeader = true,
  showFooter = false,
  hideGdprImage = false,
}) => {
  const isBigScreen = useMatchMedia(mediaConfined);
  const { getHostname } = useHost();
  const mainHost = getMainHost(getHostname());

  return (
    <>
      <Styled.Container $isBlankPage={showSimpleHeader && !showFooter}>
        <div>
          {(isBigScreen && !hideHeader) || forceShowHeader ? (
            <HeaderLoggedOut
              hideLoginButton={showSimpleHeader}
              hideSignupButton={showSimpleHeader}
            />
          ) : null}

          {children}
        </div>
        <Styled.GdprImageContainer
          $isBlankPage={showSimpleHeader && !showFooter}
        >
          {!hideGdprImage ? <GdprImage /> : null}
        </Styled.GdprImageContainer>
      </Styled.Container>
      {showFooter ? <Footer homeLink={mainHost} /> : null}
    </>
  );
};
