import type React from 'react';
import styled from 'styled-components';

import { xdlColorText } from '@xing-com/tokens';

import { CenterImage } from './center-image';

const DarkModeEnabledText = styled.text`
  @media (prefers-color-scheme: dark) {
    fill: ${xdlColorText};
  }
`;

const DarkModeEnabledG = styled.g`
  @media (prefers-color-scheme: dark) {
    fill: ${xdlColorText};
  }
`;

export const GdprEngSvg: React.FC = () => (
  <CenterImage>
    <svg viewBox="0 0 287 89" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs />
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M149.75,13.21 C143.548964,13.4249037 137.344555,12.8944402 131.27,11.63 C125.748034,10.4918256 120.489759,8.3240594 115.77,5.24 C115.306111,4.92136842 114.693889,4.92136842 114.23,5.24 C109.493476,8.31981327 104.222951,10.4870372 98.69,11.63 C92.6154447,12.8944402 86.411036,13.4249037 80.21,13.21 C79.4200906,13.1809379 78.7486144,13.7817324 78.69,14.57 C78.25,20.72 77.13,45.36 85.93,62.63 C92.1126417,74.4770371 102.254631,83.7803483 114.59,88.92 C114.852265,89.0300043 115.147735,89.0300043 115.41,88.92 C127.731348,83.7778295 137.856652,74.4735924 144.02,62.63 C150.72,49.48 151.68,32.07 151.56,21.78 C151.56,21.78 151.76,16 151.76,15 C151.777706,14.48019 151.563277,13.9794386 151.174861,13.6335357 C150.786445,13.2876328 150.264294,13.1324248 149.75,13.21 Z"
          id="Shape"
          fill="#FFFFFF"
        />
        <DarkModeEnabledText
          id="GDPR"
          fill="#4D4D4D"
          transform="translate(206.875844, 48.251600) rotate(-0.270000) translate(-206.875844, -48.251600) "
          fontSize="39.29"
          fontWeight="normal"
        >
          <tspan x="158.375844" y="61.7515999">
            GDPR
          </tspan>
        </DarkModeEnabledText>
        <path
          d="M283.64,35.34 C283.64,35.34 283.53,34.9 279.93,38.55 C276.33,42.2 269.28,48.66 269.28,48.66 L263.67,45.32 C263.237709,44.8877086 262.60763,44.7188796 262.017109,44.8771093 C261.426588,45.0353389 260.965339,45.4965883 260.807109,46.0871093 C260.64888,46.6776302 260.817709,47.3077086 261.25,47.74 L268.94,57.08 C269.038262,57.2016249 269.152649,57.3092832 269.28,57.4 C269.496055,57.5547289 269.746665,57.6542861 270.01,57.69 C270.099727,57.699908 270.190273,57.699908 270.28,57.69 C270.734576,57.6949719 271.171533,57.514411 271.49,57.19 C271.615906,57.0681566 271.720669,56.9262198 271.8,56.77 C271.8,56.77 279.14,46.66 283.24,41.67 C286.39,37.82 286.44,37.28 286.44,37.28 C286.975879,36.5022518 286.788935,35.4384504 286.02,34.89 C285.644944,34.6333405 285.183147,34.5365382 284.736591,34.620971 C284.290036,34.7054038 283.895459,34.9641256 283.64,35.34 Z"
          id="Shape"
          fill="#B0D400"
        />
        <g
          id="new_lock-46"
          transform="translate(99.000000, 25.000000)"
          fill="#4D4D4D"
        >
          <path
            d="M7.35,11.09 L7.35,9.24 C7.26538462,7.2933862 8.25569141,5.45731183 9.92881691,4.45875479 C11.6019424,3.46019776 13.6880576,3.46019776 15.3611831,4.45875479 C17.0343086,5.45731183 18.0246154,7.2933862 17.94,9.24 L17.94,11.09 C17.94,11.7085589 18.4414411,12.21 19.06,12.21 L19.91,12.21 C20.5285589,12.21 21.03,11.7085589 21.03,11.09 L21.03,9.23 C21.1358536,6.16526931 19.5612367,3.28693363 16.9233407,1.72319338 C14.2854446,0.159453128 11.0045554,0.159453128 8.36665931,1.72319338 C5.72876325,3.28693363 4.15414643,6.16526931 4.26,9.23 L4.26,11.08 C4.26,11.6985589 4.76144108,12.2 5.38,12.2 L6.23,12.2 C6.84467463,12.2000245 7.34451183,11.7046501 7.35,11.09 Z"
            id="Shape"
          />
          <path
            d="M21.91,14.07 L3.55,14.07 C1.9561752,14.0754865 0.665486537,15.3661752 0.66,16.96 L0.66,29.61 C0.659995401,30.3782114 0.965849,31.1148138 1.50999387,31.6570823 C2.05413875,32.1993508 2.79179318,32.5026582 3.56,32.5 L21.75,32.5 C23.3461029,32.5 24.64,31.2061029 24.64,29.61 L24.64,17 C24.6535924,15.4528962 23.4542045,14.1656411 21.91,14.07 Z M14.64,25.17 C14.64,26.2745695 13.7445695,27.17 12.64,27.17 C11.5354305,27.17 10.64,26.2745695 10.64,25.17 L10.64,21.17 C10.64,20.0654305 11.5354305,19.17 12.64,19.17 C13.7445695,19.17 14.64,20.0654305 14.64,21.17 L14.64,25.17 Z"
            id="Shape"
          />
        </g>
        <DarkModeEnabledG
          id="Group"
          transform="translate(-0.970000, 26.900000)"
          fill="#4D4D4D"
          fontSize="18"
          fontWeight="normal"
        >
          <text id="P">
            <tspan x="2" y="17">
              P
            </tspan>
          </text>
          <text id="ri">
            <tspan x="12.28" y="17">
              ri
            </tspan>
          </text>
          <text id="v">
            <tspan x="24.45" y="17">
              v
            </tspan>
          </text>
          <text id="acy">
            <tspan x="33.18" y="17">
              acy
            </tspan>
          </text>
          <text id="at-XING">
            <tspan x="0.65" y="38.6">
              at XING
            </tspan>
          </text>
        </DarkModeEnabledG>
        <path
          d="M148.23,16.78 C147.125431,16.78 146.23,17.6754305 146.23,18.78 C146.23,22.02 146.23,26.27 145.81,30.97 L149.81,30.97 C150.19,26.25 150.27,21.97 150.23,18.73 C150.202867,17.6450049 149.315334,16.7796608 148.23,16.78 Z"
          id="Shape"
          fill="#333333"
        />
        <path
          d="M77.32,12.23 C83.5093485,12.396432 89.6971678,11.8461053 95.76,10.59 C101.40921,9.41771748 106.797327,7.22519452 111.66,4.12 C116.513688,7.22856134 121.895897,9.4214388 127.54,10.59 C133.785102,11.8923779 140.164558,12.4363105 146.54,12.21 C147.641006,12.1613319 148.495158,11.2311551 148.45,10.13 C148.42666,9.59937833 148.193213,9.09983266 147.80114,8.7415269 C147.409068,8.38322114 146.890573,8.19558588 146.36,8.22 C140.319713,8.42086365 134.277029,7.90052136 128.36,6.67 C123.090412,5.58971479 118.072393,3.52347173 113.57,0.58 C112.429463,-0.195948165 110.930537,-0.195948165 109.79,0.58 C105.270817,3.51918206 100.24056,5.58487757 94.96,6.67 C89.0429714,7.90052136 83.0002868,8.42086365 76.96,8.22 L76.81,8.22 C74.9945396,8.21560223 73.4862879,9.61893208 73.36,11.43 C72.9222592,17.9505737 72.9556794,24.4942376 73.46,31.01 L77.46,31.01 C76.9610095,24.7615454 76.914221,18.4851994 77.32,12.23 Z"
          id="Shape"
          fill="#333333"
        />
        <path
          d="M145.88,30 C145.21,39 143.39,49.93 138.88,58.73 C138.3,59.86 137.69,60.95 137.06,62 L141.75,62 C142.01,61.52 142.27,61 142.52,60.54 C147.34,51.09 149.25,39.48 149.94,30 L145.88,30 Z"
          id="Shape"
          fill="#C43759"
        />
        <path
          d="M84.38,58.73 C79.9,49.93 78.06,39.18 77.38,30 L73.38,30 C74.07,39.67 76,51.11 80.81,60.54 C81.06,61.04 81.32,61.54 81.58,62 L86.2,62 C85.5666667,60.9466667 84.96,59.8566667 84.38,58.73 Z"
          id="Shape"
          fill="#C43759"
        />
        <path
          d="M137.67,61 C131.705091,71.2489921 122.584189,79.2929257 111.67,83.93 C100.755423,79.2935409 91.6343474,71.2494536 85.67,61 L81.11,61 C87.5337787,73.0775546 97.9547422,82.5476229 110.59,87.79 C110.950538,87.9289274 111.333621,88.0001198 111.72,88 C112.106295,87.9992154 112.489215,87.9280533 112.85,87.79 C125.447975,82.5282499 135.831256,73.0603095 142.23,61 L137.67,61 Z"
          id="Shape"
          fill="#F2B741"
        />
      </g>
    </svg>
  </CenterImage>
);
