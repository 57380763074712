import type React from 'react';

import type { LoginFormProps } from '@xing-com/crate-login-types';
import { useHost } from '@xing-com/crate-xinglet';

export const LoginForm: React.FC<LoginFormProps> = (props) => {
  const host = useHost();

  return (
    <host.XingletLoader {...props} name="@xing-com/crate-login-login-form" />
  );
};
