import styled from 'styled-components';

import { mediaWideNavless, mediaXWide } from '@xing-com/layout-tokens';
import { HeaderLoggedOut } from '@xing-com/platform-layout-header-logged-out';
import {
  spaceXS,
  cornerRadius3XL,
  space4XL,
  space6XL,
  spaceL,
  spaceXL,
  spaceXXL,
} from '@xing-com/tokens';
import { XingLogo as BreweryXingLogo } from '@xing-com/xing-logo';

import background from './images/background.png';

const TopBar = styled(HeaderLoggedOut)`
  margin-bottom: ${spaceL};

  @media ${mediaWideNavless} {
    display: none;
  }
`;

const PADDING_SIZE_TABLET = space4XL;
const PADDING_SIZE_DESKTOP = space6XL;
const LoginContainer = styled.div`
  display: flex;
  height: 100vh;

  @media ${mediaWideNavless} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const Column = styled.div`
  width: 100%;
  display: flex;
  padding: ${spaceL};
  flex-direction: column;
  margin: 0 auto;
  align-items: center;

  @media ${mediaWideNavless} {
    justify-content: center;
    padding: ${PADDING_SIZE_TABLET};
  }
`;

const ColumnWithBackground = styled(Column)`
  display: none;

  @media ${mediaWideNavless} {
    display: flex;
    background: url(${background}) no-repeat;
    background-size: cover;
  }
`;

const Energizer = styled.img`
  max-width: 100%;
  object-fit: contain;
  border-radius: ${cornerRadius3XL};

  @media ${mediaWideNavless} {
    max-height: calc(100vh - ${PADDING_SIZE_TABLET} * 2);
  }

  @media ${mediaXWide} {
    max-height: calc(100vh - ${PADDING_SIZE_DESKTOP} * 2);
  }
`;

const XingLogo = styled(BreweryXingLogo)`
  position: absolute;
  padding-top: ${spaceXS};
  top: ${spaceXL};
  left: ${spaceXXL};

  @media ${mediaXWide} {
    left: ${space4XL};
  }
`;

export const Styled = {
  TopBar,
  LoginContainer,
  ColumnWithBackground,
  Column,
  Energizer,
  XingLogo,
};
