import styled from 'styled-components';

import { Divider } from '@xing-com/divider';
import { spaceXS, space3XL } from '@xing-com/tokens';

import { Meta as BreweryMeta } from '../typography';

const Separator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1em;
  padding-top: ${space3XL};
  padding-bottom: ${space3XL};
`;

const SeparatorLine = styled(Divider)`
  width: 100%;
`;

const SeparatorText = styled(BreweryMeta)`
  margin: 0 ${spaceXS};
`;

export const Styled = {
  Separator,
  SeparatorLine,
  SeparatorText,
};
