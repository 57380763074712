import type {
  HeadlineProps,
  BodyCopyProps,
  MetaProps,
} from '@xing-com/typography';
import {
  Headline as BreweryHeadline,
  BodyCopy as BreweryBodyCopy,
  Meta as BreweryMeta,
} from '@xing-com/typography';

export const Headline: React.FC<HeadlineProps> = ({ ...props }) => {
  return <BreweryHeadline size="xxlarge" sizeConfined="xxxlarge" {...props} />;
};

export const BodyCopy: React.FC<BodyCopyProps> = ({ ...props }) => {
  return <BreweryBodyCopy size="small" sizeConfined="large" {...props} />;
};

export const Meta: React.FC<MetaProps> = ({ ...props }) => {
  return <BreweryMeta size="small" sizeConfined="large" {...props} />;
};
