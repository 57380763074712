import styled from 'styled-components';

import { spaceXS, spaceXXL } from '@xing-com/tokens';

import { Headline as LayoutHeadline } from '../typography';

const HeadlineContainer = styled.div`
  margin-bottom: ${spaceXXL};
`;

const Headline = styled(LayoutHeadline)`
  margin-bottom: ${spaceXS};
`;

export const Styled = {
  HeadlineContainer,
  Headline,
};
