import type {
  ButtonProps as BreweryButtonProps,
  TextButtonProps as BreweryTextButtonProps,
} from '@xing-com/button';

import { Styled } from './button.styles';

export const Button: React.FC<BreweryButtonProps> = ({ ...buttonProps }) => {
  return (
    <Styled.StretchButtonGroup>
      <Styled.StretchButton
        size="medium"
        sizeConfined="large"
        {...buttonProps}
      />
    </Styled.StretchButtonGroup>
  );
};

export const TextButton: React.FC<BreweryTextButtonProps> = ({
  ...buttonProps
}) => {
  return (
    <Styled.StretchButtonGroup>
      <Styled.StretchTextButton
        size="medium"
        sizeConfined="large"
        {...buttonProps}
      />
    </Styled.StretchButtonGroup>
  );
};
