import styled, { css } from 'styled-components';

import {
  xdlColorBackgroundTertiary,
  xdlColorBorderSoft,
  xdlColorControlFillQuaternary,
  xdlColorControlFillSecondary,
} from '@xing-com/tokens';

const StepCounterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  margin: 48px 0;
  padding: 0;
`;

interface StepCounterItemProps {
  future?: boolean;
  past?: boolean;
}

const StepCounterItem = styled.div<StepCounterItemProps>`
  border-radius: 50%;
  width: 14px;
  height: 14px;
  font-size: 12px;
  text-align: center;
  line-height: 14px;
  background-color: ${xdlColorControlFillQuaternary};
  color: white;
  position: relative;

  ${(props) =>
    props.future &&
    css`
      background-color: ${xdlColorBorderSoft};
    `}

  ${(props) =>
    props.past &&
    css`
      border: 1px solid ${xdlColorControlFillQuaternary};
      color: ${xdlColorControlFillQuaternary};
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  @media (prefers-color-scheme: dark) {
    background-color: ${xdlColorControlFillSecondary};

    ${(props) =>
      props.future &&
      css`
        background-color: ${xdlColorBorderSoft};
      `}

    ${(props) =>
      props.past &&
      css`
        border: 1px solid ${xdlColorBackgroundTertiary};
        color: ${xdlColorBackgroundTertiary};
      `}
  }
`;

interface StepCounterSeparatorProps {
  past?: boolean;
}

const StepCounterSeparator = styled.div<StepCounterSeparatorProps>`
  height: 1px;
  flex: 1 0 auto;
  margin: 0;
  background-color: ${xdlColorBorderSoft};

  ${(props) =>
    props.past &&
    css`
      background-color: ${xdlColorControlFillQuaternary};
      height: 2px;
    `}
  @media (prefers-color-scheme: dark) {
    ${(props) =>
      props.past &&
      css`
        background-color: ${xdlColorControlFillSecondary};
      `}
  }
`;

export const Styled = {
  StepCounterContainer,
  StepCounterItem,
  StepCounterSeparator,
};
