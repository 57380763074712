import type React from 'react';
import styled from 'styled-components';

import { xdlColorText } from '@xing-com/tokens';

import { CenterImage } from './center-image';

const DarkModeEnabledText = styled.text`
  @media (prefers-color-scheme: dark) {
    fill: ${xdlColorText};
  }
`;

const DarkModeEnabledG = styled.g`
  @media (prefers-color-scheme: dark) {
    fill: ${xdlColorText};
  }
`;

export const GdprGerSvg: React.FC = () => (
  <CenterImage>
    <svg viewBox="0 0 350 89" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M189.75,13.21 C183.548964,13.4249037 177.344555,12.8944402 171.27,11.63 C165.748034,10.4918256 160.489759,8.3240594 155.77,5.24 C155.306111,4.92136842 154.693889,4.92136842 154.23,5.24 C149.493476,8.31981327 144.222951,10.4870372 138.69,11.63 C132.615445,12.8944402 126.411036,13.4249037 120.21,13.21 C119.420091,13.1809379 118.748614,13.7817324 118.69,14.57 C118.25,20.72 117.13,45.36 125.93,62.63 C132.112642,74.4770371 142.254631,83.7803483 154.59,88.92 C154.852265,89.0300043 155.147735,89.0300043 155.41,88.92 C167.731348,83.7778295 177.856652,74.4735924 184.02,62.63 C190.72,49.48 191.68,32.07 191.56,21.78 C191.56,21.78 191.76,16 191.76,15 C191.777706,14.48019 191.563277,13.9794386 191.174861,13.6335357 C190.786445,13.2876328 190.264294,13.1324248 189.75,13.21 Z"
          id="Shape"
          fill="#FFFFFF"
        />
        <DarkModeEnabledText
          id="DSGVO"
          fill="#4D4D4D"
          transform="translate(260.424985, 55.327830) rotate(-0.270000) translate(-260.424985, -55.327830) "
          fontSize="39.29"
          fontWeight="normal"
        >
          <tspan x="198.409341" y="61.6878299">
            DSGVO
          </tspan>
        </DarkModeEnabledText>
        <path
          d="M346.64,35.34 C346.64,35.34 346.53,34.9 342.93,38.55 C339.33,42.2 332.28,48.66 332.28,48.66 L326.67,45.32 C326.001735,44.6517359 324.918265,44.6517361 324.25,45.3200005 C323.581736,45.9882648 323.581736,47.0717354 324.25,47.74 L331.94,57.08 C332.038262,57.2016249 332.152649,57.3092832 332.28,57.4 C332.496055,57.5547289 332.746665,57.6542861 333.01,57.69 C333.099727,57.699908 333.190273,57.699908 333.28,57.69 C333.734576,57.6949719 334.171533,57.514411 334.49,57.19 C334.615906,57.0681566 334.720669,56.9262198 334.8,56.77 C334.8,56.77 342.14,46.66 346.24,41.67 C349.39,37.82 349.44,37.28 349.44,37.28 C349.975879,36.5022518 349.788935,35.4384504 349.02,34.89 C348.644944,34.6333405 348.183147,34.5365382 347.736591,34.620971 C347.290036,34.7054038 346.895459,34.9641256 346.64,35.34 Z"
          id="Shape"
          fill="#B0D400"
        />
        <g
          id="new_lock-9"
          transform="translate(139.000000, 25.000000)"
          fill="#4D4D4D"
        >
          <path
            d="M7.35,11.09 L7.35,9.24 C7.26538462,7.2933862 8.25569141,5.45731183 9.92881691,4.45875479 C11.6019424,3.46019776 13.6880576,3.46019776 15.3611831,4.45875479 C17.0343086,5.45731183 18.0246154,7.2933862 17.94,9.24 L17.94,11.09 C17.94,11.7085589 18.4414411,12.21 19.06,12.21 L19.91,12.21 C20.5285589,12.21 21.03,11.7085589 21.03,11.09 L21.03,9.23 C21.1358536,6.16526931 19.5612367,3.28693363 16.9233407,1.72319338 C14.2854446,0.159453128 11.0045554,0.159453128 8.36665931,1.72319338 C5.72876325,3.28693363 4.15414643,6.16526931 4.26,9.23 L4.26,11.08 C4.26,11.6985589 4.76144108,12.2 5.38,12.2 L6.23,12.2 C6.84467463,12.2000245 7.34451183,11.7046501 7.35,11.09 Z"
            id="Shape"
          />
          <path
            d="M21.91,14.07 L3.55,14.07 C1.9561752,14.0754865 0.665486537,15.3661752 0.66,16.96 L0.66,29.61 C0.659995401,30.3782114 0.965849,31.1148138 1.50999387,31.6570823 C2.05413875,32.1993508 2.79179318,32.5026582 3.56,32.5 L21.75,32.5 C23.3461029,32.5 24.64,31.2061029 24.64,29.61 L24.64,17 C24.6535924,15.4528962 23.4542045,14.1656411 21.91,14.07 Z M14.64,25.17 C14.64,26.2745695 13.7445695,27.17 12.64,27.17 C11.5354305,27.17 10.64,26.2745695 10.64,25.17 L10.64,21.17 C10.64,20.0654305 11.5354305,19.17 12.64,19.17 C13.7445695,19.17 14.64,20.0654305 14.64,21.17 L14.64,25.17 Z"
            id="Shape"
          />
        </g>
        <DarkModeEnabledG
          id="Group"
          transform="translate(-1.190000, 26.900000)"
          fill="#4D4D4D"
          fontSize="18"
          fontWeight="normal"
        >
          <text id="D">
            <tspan x="0" y="17">
              D
            </tspan>
          </text>
          <text id="a">
            <tspan x="11.48" y="17">
              a
            </tspan>
          </text>
          <text id="t">
            <tspan x="21.31" y="17">
              t
            </tspan>
          </text>
          <text id="enschutz">
            <tspan x="27.76" y="17">
              enschutz
            </tspan>
          </text>
          <text id="bei-XING">
            <tspan x="31.77" y="38.6">
              bei XING
            </tspan>
          </text>
        </DarkModeEnabledG>
        <path
          d="M188.23,16.78 C187.125431,16.78 186.23,17.6754305 186.23,18.78 C186.23,22.02 186.23,26.27 185.81,30.97 L189.81,30.97 C190.19,26.25 190.27,21.97 190.23,18.73 C190.202867,17.6450049 189.315334,16.7796608 188.23,16.78 Z"
          id="Shape"
          fill="#333333"
        />
        <path
          d="M117.32,12.23 C123.509349,12.396432 129.697168,11.8461053 135.76,10.59 C141.40921,9.41771748 146.797327,7.22519452 151.66,4.12 C156.513688,7.22856134 161.895897,9.4214388 167.54,10.59 C173.785102,11.8923779 180.164558,12.4363105 186.54,12.21 C187.641006,12.1613319 188.495158,11.2311551 188.45,10.13 C188.42666,9.59937833 188.193213,9.09983266 187.80114,8.7415269 C187.409068,8.38322114 186.890573,8.19558588 186.36,8.22 C180.319713,8.42086365 174.277029,7.90052136 168.36,6.67 C163.090412,5.58971479 158.072393,3.52347173 153.57,0.58 C152.429463,-0.195948165 150.930537,-0.195948165 149.79,0.58 C145.270817,3.51918206 140.24056,5.58487757 134.96,6.67 C129.042971,7.90052136 123.000287,8.42086365 116.96,8.22 L116.81,8.22 C114.99454,8.21560223 113.486288,9.61893208 113.36,11.43 C112.922259,17.9505737 112.955679,24.4942376 113.46,31.01 L117.46,31.01 C116.96101,24.7615454 116.914221,18.4851994 117.32,12.23 Z"
          id="Shape"
          fill="#333333"
        />
        <path
          d="M185.88,30 C185.21,39 183.39,49.93 178.88,58.73 C178.3,59.86 177.69,60.95 177.06,62 L181.75,62 C182.01,61.52 182.27,61 182.52,60.54 C187.34,51.09 189.25,39.48 189.94,30 L185.88,30 Z"
          id="Shape"
          fill="#C43759"
        />
        <path
          d="M124.38,58.73 C119.9,49.93 118.06,39.18 117.38,30 L113.38,30 C114.07,39.67 116,51.11 120.81,60.54 C121.06,61.04 121.32,61.54 121.58,62 L126.2,62 C125.566667,60.9466667 124.96,59.8566667 124.38,58.73 Z"
          id="Shape"
          fill="#C43759"
        />
        <path
          d="M177.67,61 C171.705091,71.2489921 162.584189,79.2929257 151.67,83.93 C140.755423,79.2935409 131.634347,71.2494536 125.67,61 L121.11,61 C127.533779,73.0775546 137.954742,82.5476229 150.59,87.79 C150.950538,87.9289274 151.333621,88.0001198 151.72,88 C152.106295,87.9992154 152.489215,87.9280533 152.85,87.79 C165.447975,82.5282499 175.831256,73.0603095 182.23,61 L177.67,61 Z"
          id="Shape"
          fill="#F2B741"
        />
      </g>
    </svg>
  </CenterImage>
);
