import type React from 'react';
import { useEffect, useRef, useState } from 'react';

import { useTracking } from '@xing-com/crate-communication-tracking';
import { useSearchParameter } from '@xing-com/crate-hooks-use-search-parameter';
import { LoginForm } from '@xing-com/crate-login-account-packages';
import { BackgroundLayout } from '@xing-com/crate-login-layout';

import { useLoginPage } from './hooks';
import { messages } from './messages';

export const Login: React.FC = () => {
  const { execute, result, loading } = useLoginPage();
  const { track } = useTracking();
  const { getSearchParameter, deleteSearchParameter } = useSearchParameter();
  const [didResetPassword, setDidResetPassword] = useState(false);

  let signupChannel = getSearchParameter('signup_channel');
  // we want to actively distinguish between registrations
  // being made on the login page and on the registration page (e.g. via oauth)
  // hence we need to overwrite those special fallback channels
  if (!signupChannel || signupChannel === 'minireg_fullpage') {
    signupChannel = 'minireg_loginpage';
  }

  useEffect(() => {
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    track({
      type: 'pageview',
      channel: 'wbm/Welcome',
      page: 'wbm/Welcome/login',
    });
  }, [track]);

  const initialErrorKey = useRef(getSearchParameter('error') || '');
  useEffect(() => {
    if (initialErrorKey.current) {
      deleteSearchParameter('error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const didResetPassword = window.localStorage.getItem('didResetPassword');

    if (didResetPassword === 'true') {
      window.localStorage.removeItem('didResetPassword');
      setDidResetPassword(true);
    }
  }, []);

  if (loading === null || loading) {
    return null;
  }

  const { authPartner } = result;

  return (
    <BackgroundLayout>
      <LoginForm
        signupChannel={signupChannel}
        authPartner={
          authPartner
            ? {
                website: authPartner.website,
                name: authPartner.name,
              }
            : undefined
        }
        headlineMessage={
          didResetPassword ? messages.postRecoveryTitle : undefined
        }
        noSignupLink={didResetPassword}
        showTroubleLoggingInLink={!didResetPassword}
        showAppleSignIn={!didResetPassword}
        showGoogleSignIn={!didResetPassword}
        initialErrorKey={initialErrorKey.current}
      />
    </BackgroundLayout>
  );
};
