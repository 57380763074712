import styled from 'styled-components';

import { xdlColorBackground, xdlColorXING } from '@xing-com/tokens';

const OverlayWrapper = styled.div`
  color: ${xdlColorXING};
  background-color: ${xdlColorBackground};
  opacity: 0.7;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Styled = {
  OverlayWrapper,
};
