import type { Xinglet, msw } from '@xing-com/crate-xinglet';

import { Login } from './login';

export default class LoginXinglet implements Xinglet {
  public async registerMocks(msw: msw): Promise<void> {
    (await import('./mocks')).setupMocking(msw);
  }

  public getComponent() {
    return (): JSX.Element => <Login />;
  }
}
