import styled from 'styled-components';

import { mediaConfined } from '@xing-com/layout-tokens';
import { space4XL, spaceXL } from '@xing-com/tokens';

const Container = styled.div<{ $isBlankPage: boolean }>`
  ${(props) => (props.$isBlankPage ? 'height: 100vh;' : '')}

  align-items: space-between;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 100%;
  padding: 0 ${spaceXL};
  width: 460px;

  @media ${mediaConfined} {
    padding: 0;
  }
`;

const GdprImageContainer = styled.div<{ $isBlankPage: boolean }>`
  ${(props) => (props.$isBlankPage ? 'align-items: end;' : '')}

  display: flex;
  height: 100%;
  margin: auto;
  max-width: 120px;

  & svg {
    width: 100%;
  }

  @media ${mediaConfined} {
    padding-bottom: ${space4XL};
  }
`;

export const Styled = {
  Container,
  GdprImageContainer,
};
