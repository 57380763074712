import styled, { css } from 'styled-components';

import { PopOver as BreweryPopOver } from '@xing-com/pop-over';
import { spaceXXL } from '@xing-com/tokens';

const TextWrapper = styled.div<{ center?: boolean }>`
  margin-bottom: ${spaceXXL};

  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `}
`;

const PopOver = styled(BreweryPopOver)`
  left: 50%;
  transform: translateX(-50%);
`;

export const Styled = {
  TextWrapper,
  PopOver,
};
