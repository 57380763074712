export const messages = {
  metaTitle: {
    id: 'LOGIN_FRONTEND_META_TITLE',
    defaultMessage: 'Bei XING anmelden',
  },
  metaDescription: {
    id: 'LOGIN_FRONTEND_META_DESCRIPTION',
    defaultMessage:
      'Finden Sie bei XING neue Jobs, spannende Kontakte und inspirierende News.',
  },
};
