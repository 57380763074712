import type React from 'react';
import { useIntl } from 'react-intl';

import { GdprGerSvg } from './gdpr-de';
import { GdprEngSvg } from './gdpr-en';

export const GdprImage: React.FC = () => {
  const { locale } = useIntl();

  return locale === 'de' ? <GdprGerSvg /> : <GdprEngSvg />;
};
